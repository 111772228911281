import {
  SYSTEM_PAGE,
  HOME_PATH,
  CART_PATH,
  BOOKING_PATH,
  CHECKOUT_PATH,
  PRODUCT_PAGE,
  COLLECTION_PAGE,
  CONTACT_PATH,
  PRODUCTS_PATH,
  COLLECTIONS_PATH,
} from "@iamilyas/store-template-library";

// ----------------------------------------------------------------------

export const PATH_PAGE = {
  home: "/",
  products: "/products",
  product: (name) => `/products/${name}`,
  collections: "/collections",
  collection: (name) => `/collections/${name}`,
  contactUs: "/contact-us",
  cart: "/cart",
  checkout: "/checkout",
  booking: "/bookings",
  checkoutRedirect: (orderId) => `/checkout/${orderId}/complete`,
  // Can ONLY be called from checkout page for external reference.
  checkoutRedirectUrl: (orderId) =>
    `${window.location.href}/${orderId}/complete`,
  page404: "/404",
  page500: "/500",
  custom: "/:name",
};

export const buildNavigationPath = (type, resource) => {
  if (type === SYSTEM_PAGE) {
    return getSystemPath(resource);
  }
  if (type === PRODUCT_PAGE) {
    if (resource) {
      return PATH_PAGE.product(resource);
    }
    return PATH_PAGE.page404;
  }
  if (type === COLLECTION_PAGE) {
    if (resource) {
      return PATH_PAGE.collection(resource);
    }
    return PATH_PAGE.page404;
  }
  // DEFAULT - CUSTOM_PAGE
  return `/${resource}`;
};

const getSystemPath = (resource) => {
  if (resource === HOME_PATH) {
    return PATH_PAGE.home;
  }
  if (resource === CART_PATH) {
    return PATH_PAGE.cart;
  }
  if (resource === CHECKOUT_PATH) {
    return PATH_PAGE.checkout;
  }
  if (resource === BOOKING_PATH) {
    return PATH_PAGE.booking;
  }
  if (resource === CONTACT_PATH) {
    return PATH_PAGE.contactUs;
  }
  if (resource === PRODUCTS_PATH) {
    return PATH_PAGE.products;
  }
  if (resource === COLLECTIONS_PATH) {
    return PATH_PAGE.collections;
  }
  return PATH_PAGE.page404;
};
