import { findIndex, isEmpty, reduce } from "lodash";

export const getCartCount = (cart) => {
  if (isEmpty(cart)) {
    return 0;
  }
  const quantities = cart.map((_product) => _product.quantity);
  return reduce(quantities, (sum, n) => sum + n);
};

export const isAddedToCart = (cart, id, variantId) => {
  const foundIndex = findIndex(
    cart,
    (_product) => _product.id === id && _product.variantId === variantId
  );
  return {
    isAddedToCart: foundIndex >= 0,
    product: cart[foundIndex],
  };
};

export const getCartRetailTotal = (products) => {
  if (isEmpty(products)) {
    return 0;
  }
  const totals = products.map(
    (_product) => _product.price.retailAmount * _product.quantity
  );
  return reduce(totals, (sum, n) => sum + n);
};

export const getCartRetailTotalInMinorUnits = (products) => {
  if (isEmpty(products)) {
    return 0;
  }
  const totals = products.map(
    (_product) => _product.price.retailAmountInMinorUnits * _product.quantity
  );
  return reduce(totals, (sum, n) => sum + n);
};
