import axios from "../utils/axios";

export async function getBookingLocations(bookingUuid, page, size) {
  const response = await axios.get(`/host/bookings/${bookingUuid}/locations`, {
    params: { page, size },
  });
  return response.data;
}

export async function getBookingServices(
  currencyCode,
  bookingUuid,
  locationUuid,
  page,
  size
) {
  const response = await axios.get(
    `/host/bookings/${bookingUuid}/locations/${locationUuid}/services`,
    {
      params: { currency: currencyCode, page, size },
    }
  );
  return response.data;
}

export async function getBookingAvailability(
  bookingUuid,
  locationUuid,
  from,
  to
) {
  const response = await axios.get(
    `/host/bookings/${bookingUuid}/locations/${locationUuid}/availability`,
    {
      params: {
        from,
        to,
      },
    }
  );
  return response.data;
}

export async function getBookingSummary(request) {
  const response = await axios.post(`/host/bookings/summary`, request);
  return response.data;
}

export async function startBookingCheckout(request) {
  const response = await axios.post(`/host/bookings`, request);
  return response.data;
}

export async function completeBookingCheckout(id, status, failureReason) {
  const response = await axios.put(`/host/bookings/${id}/complete`, {
    status,
    failureReason,
  });
  return response.data;
}
