import { paramCase } from "change-case";
import { defaultTo } from "lodash";

export const PAYMENT_PROVIDER_DESCRIPTION = "Payment to complete your order";
export const STRIPE_PAYMENT_LABEL = "Shopping Cart Total";

// PAYMENT PROVIDERS
export const REVLOUT_PAYMENT_PROVIDER_KEY = "revolut";
export const PAYPAL_PAYMENT_PROVIDER_KEY = "paypal";
export const KLARNA_PAYMENT_PROVIDER_KEY = "klarna";
export const CLEARPAY_PAYMENT_PROVIDER_KEY = "clearpay";
export const STRIPE_PAYMENT_PROVIDER_KEY = "stripe";
export const PAY_ON_ARRIVAL_KEY = "cash";

export const isStripeProdiver = (key) =>
  paramCase(defaultTo(key, "")) === STRIPE_PAYMENT_PROVIDER_KEY;

// PAYMENT STATUS
export const PAYMENT_SUCCESS_STATUS = "SUCCESS";
export const PAYMENT_PROCESSING_STATUS = "SUCCESS_PROCESSING";
export const PAYMENT_FAILED_STATUS = "FAILED";
export const PAYMENT_CANCELLED_STATUS = "CANCELLED";

// URLS
export const CLEARPAY_SCRIPT_URL = "https://js.afterpay.com/afterpay-1.x.js";
export const KLARNA_SCRIPT_URL = "https://x.klarnacdn.net/kp/lib/v1/api.js";
export const KLARNA_ON_SITE_MESSAGING_SCRIPT_URL =
  "https://js.klarna.com/web-sdk/v1/klarna.js";

// BOOKING STATUS
export const BOOKING_EXT_SUCCESS_STATUS = "SUCCESS";
export const BOOKING_EXT_FAILED_STATUS = "FAILED";
