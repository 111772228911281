import { Navigate, useRoutes } from "react-router-dom";
import BookingPage from "../pages/BookingPage";
import CartPage from "../pages/CartPage";
import CheckoutPage from "../pages/CheckoutPage";
import CheckoutRedirectPage from "../pages/CheckoutRedirectPage";
import CollectionPage from "../pages/CollectionPage";
import CollectionsPage from "../pages/CollectionsPage";
import ContactUsPage from "../pages/ContactUsPage";
import HomePage from "../pages/HomePage";
import Page500 from "../pages/Page500";
import Page404 from "../pages/Page404";
import ProductPage from "../pages/ProductPage";
import ProductsPage from "../pages/ProductsPage";
import CustomPage from "../pages/CustomPage";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/",
      children: [
        { path: "/", element: <HomePage /> },
        { path: "products", element: <ProductsPage /> },
        { path: "products/:name", element: <ProductPage /> },
        { path: "collections", element: <CollectionsPage /> },
        { path: "collections/:name", element: <CollectionPage /> },
        { path: "contact-us", element: <ContactUsPage /> },
        { path: "cart", element: <CartPage /> },
        { path: "checkout", element: <CheckoutPage /> },
        { path: "bookings", element: <BookingPage /> },
        {
          path: "checkout/:orderId/complete",
          element: <CheckoutRedirectPage />,
        },
        { path: "500", element: <Page500 /> },
        { path: "404", element: <Page404 /> },
        { path: ":name", element: <CustomPage /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
