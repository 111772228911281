import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  BookingPageTemplate,
  BOOKING_PATH,
} from "@iamilyas/store-template-library";
import { useNavigate } from "react-router-dom";
import useConfig from "src/hooks/useConfig";
import GoogleAnalyticsTrackPage from "src/components/GoogleAnalyticsTrackPage";
import { chooseCurrency } from "src/utils/currency";
import { scrollToTop } from "src/utils/scroll";
import {
  getBookingAvailability,
  getBookingServices,
  getBookingLocations,
  getBookingSummary,
  completeBookingCheckout,
  startBookingCheckout,
} from "src/service/booking";
import { buildNavigationPath, PATH_PAGE } from "src/routes/paths";
import { BOOKING_EXT_SUCCESS_STATUS } from "src/utils/constant";
import {
  BOOKING_COMPLETED_SUMMARY_STAGE,
  BOOKING_INITIAL_STAGE,
  BOOKING_LOADING_SUBMIT_STAGE,
  BOOKING_LOADING_SUMMARY_STAGE,
  BOOKING_SUCCESS_STAGE,
  BOOKING_UNKNOWN_FAILURE_STAGE,
} from "src/utils/bookingStages";
import { useSelector } from "../redux/store";

export default function BookingPage() {
  const navigate = useNavigate();
  const { config } = useConfig();
  const {
    currency: prevSelectedCurrency,
    options: { currencies },
    loading: { currencies: currenciesLoading },
  } = useSelector((state) => state.store);
  const resource = config?.theme?.booking?.general?.resource;

  const capability = config?.capability;
  const storeCurrency = config?.currency;
  const currencyStore = chooseCurrency(
    prevSelectedCurrency,
    storeCurrency,
    capability,
    currencies
  );
  const [state, setState] = useState(BOOKING_INITIAL_STAGE);

  const handleGetBookingLocations = useCallback((bookingUuid, page, size) => {
    scrollToTop();
    return getBookingLocations(bookingUuid, page, size);
  }, []);

  const handleGetBookingServices = useCallback(
    (currencyCode, bookingUuid, locationUuid, page, size) => {
      scrollToTop();
      return getBookingServices(
        currencyCode,
        bookingUuid,
        locationUuid,
        page,
        size
      );
    },
    []
  );

  const handleGetBookingAvailability = useCallback(
    (bookingUuid, locationUuid, from, to) => {
      scrollToTop();
      return getBookingAvailability(bookingUuid, locationUuid, from, to);
    },
    []
  );

  const handleGetBookingSummary = useCallback(
    async (request) => {
      try {
        scrollToTop();
        setState(BOOKING_LOADING_SUMMARY_STAGE);
        const response = await getBookingSummary(request);
        setState(BOOKING_COMPLETED_SUMMARY_STAGE(response));
      } catch (e) {
        setState({ ...BOOKING_INITIAL_STAGE, summary: false });
      }
    },
    [setState]
  );

  const handleCompleteBooking = useCallback(
    async (request) => {
      try {
        setState((prev) => BOOKING_LOADING_SUBMIT_STAGE(prev.summary));
        const response = await startBookingCheckout(request);
        await completeBookingCheckout(
          response.sessionId,
          BOOKING_EXT_SUCCESS_STATUS
        );
        setState((prev) => BOOKING_SUCCESS_STAGE(prev.summary));
        scrollToTop();
      } catch (e) {
        setState((prev) => BOOKING_UNKNOWN_FAILURE_STAGE(prev.summary));
      }
    },
    [setState]
  );

  const handleReset = useCallback(
    () => setState(BOOKING_INITIAL_STAGE),
    [setState]
  );

  const handleNavigationClick = useCallback(
    (type, resource) => {
      if (type) {
        const path = buildNavigationPath(type, resource);
        if (path) {
          navigate(path);
        } else {
          navigate(PATH_PAGE.page404);
        }
      }
    },
    [navigate]
  );

  // Check if cart is empty
  useEffect(() => {
    if (!currenciesLoading && !resource) {
      navigate(PATH_PAGE.home);
    }
  }, [resource, currenciesLoading, navigate]);

  const BookingPageMemo = useMemo(() => {
    const theme = config?.theme;
    const timezone = config?.timezone;
    const logo = config?.assets;
    // Store currency not the selected currency. This is the currency all payments will be made in
    const currency = {
      symbol: currencyStore?.symbol,
      code: currencyStore?.code,
    };

    const isViewReady = Boolean(theme && resource && currency && config);

    return (
      isViewReady && (
        <BookingPageTemplate
          context={{ capability, pagePath: BOOKING_PATH }}
          logo={logo}
          config={theme}
          state={state}
          timezone={timezone}
          currency={currency}
          resource={resource}
          handleReset={handleReset}
          handleGetBookingLocations={handleGetBookingLocations}
          handleGetBookingServices={handleGetBookingServices}
          handleGetBookingAvailability={handleGetBookingAvailability}
          handleGetBookingSummary={handleGetBookingSummary}
          handleCompleteBooking={handleCompleteBooking}
          handleNavigationClick={handleNavigationClick}
        />
      )
    );
  }, [
    config,
    resource,
    capability,
    currencyStore,
    state,
    handleReset,
    handleGetBookingLocations,
    handleGetBookingServices,
    handleGetBookingAvailability,
    handleGetBookingSummary,
    handleCompleteBooking,
    handleNavigationClick,
  ]);

  return (
    <GoogleAnalyticsTrackPage currency={storeCurrency?.code}>
      {BookingPageMemo}
    </GoogleAnalyticsTrackPage>
  );
}
