import {
  BOOKING_SUCCESS_STATUS,
  BOOKING_UNKNOWN_FAILURE_STATUS,
} from "@iamilyas/store-template-library";

export const BOOKING_INITIAL_STAGE = {
  status: null,
  loading: { summary: false, booking: false },
  isComplete: false,
  summary: null,
};

export const BOOKING_LOADING_SUMMARY_STAGE = {
  status: null,
  summary: null,
  isComplete: false,
  loading: { summary: true, booking: false },
};

export const BOOKING_COMPLETED_SUMMARY_STAGE = (summary) => {
  return {
    status: null,
    summary,
    isComplete: false,
    loading: { summary: false, booking: false },
  };
};

export const BOOKING_LOADING_SUBMIT_STAGE = (summary) => {
  return {
    status: null,
    summary,
    isComplete: false,
    loading: { summary: false, booking: true },
  };
};

export const BOOKING_SUCCESS_STAGE = (summary) => {
  return {
    status: BOOKING_SUCCESS_STATUS,
    summary,
    isComplete: true,
    loading: { summary: false, booking: false },
  };
};

export const BOOKING_UNKNOWN_FAILURE_STAGE = (summary) => {
  return {
    status: BOOKING_UNKNOWN_FAILURE_STATUS,
    summary,
    isComplete: true,
    loading: { summary: false, booking: false },
  };
};
